import React, { Component } from "react"
import GetOfferForm from "./get-offer-form"
import { OptionsForm } from "./options-form"
import { ProcessCycle } from "../components/process-cycle"

export class HowItWorksBody extends Component {
    render() {
        return (
            <div className="bg-dhgray py-10 px-5 lg:px-10">
                <div className="container mx-auto">
                    <div className="lg:grid lg:grid-cols-2">
                        <div className="px-6 leading-8">
                            <ProcessCycle />

                            <h2 className="font-bold text-2xl">What Kind Of A Time Frame Should You Expect When Selling Your Sacramento House?</h2>
                            <p className="py-2">
                                After receiving your information, our team is able to make you a fair offer on your Sacramento House within 24 hours. If you accept the offer, depending on the pace you want to proceed at, we can close
                                as soon as 7 days.
                            </p>

                            <h2 className="font-semibold text-2xl pt-5">Selling Your Sacramento Home Can Be A Quick And Easy Process</h2>
                            <p className="py-2">
                                Dahlia Homes buys houses in the Greater Sacramento area. We are a direct Sacramento home buyer company, which means that we are not looking to list your house or find a different buyer for your home, you deal directly with us!
                                We are able to close deals quickly because we do not involve third parties and we pay cash! Dahlia Homes saves you from paying closing costs and extra fees when selling your Sacramento House.
                            </p>

                            <p className="py-2">
                                We buy Sacramento houses in AS-IS condition, meaning that you DO NOT have to worry about repairing or cleaning the property!
                            </p>
                        </div>

                        <div className="mx-auto py-4">

                            <div className="flex justify-center">
                                <GetOfferForm formName="How It Works Page" />
                            </div>

                            <div className="flex justify-center">
                                <OptionsForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}