import React, { Component } from "react"
import { StaticImage } from "gatsby-plugin-image"
import ArrowRight from "../icons/arrow-right"
import ArrowDown from "../icons/arrow-down"
import ArrowLeft from "../icons/arrow-left"

export class ProcessCycle extends Component {
    render() {
        return (
            <div className="container mx-auto py-5">

                <h1 className="text-2xl lg:text-3xl pb-2 font-bold text-center">How The Process Works With Dahlia Homes</h1>
                <div className="px-5 lg:px-10 grid grid-cols-3 py-5 text-xs md:text-sm justify-center text-center max-w-2xl mx-auto">

                    <div className="max-w-xs mx-auto">
                        <StaticImage src="../images/house.png" title="Sell Your Home Fast" alt="Sell Your Home Fast" className="w-14" />

                        <p className="pt-2 font-bold">Talk To Us About Your Home</p>
                        <p>Tell us your address and receive an estimated cash offer on your property</p>
                    </div>

                    <div className="flex justify-center items-center -mt-16">
                        <ArrowRight />
                    </div>

                    <div className="max-w-xs mx-auto">
                        <StaticImage src="../images/schedule.png" title="Sell Your Home As Is" alt="Sell Your As Is" className="w-14" />
                        <p className="pt-2 font-bold">Schedule A Walk Through</p>
                        <p>Take us for a walk through of your property to help us make an accurate offer</p>

                        <div className="py-7 flex justify-center">
                            <ArrowDown />
                        </div>
                    </div>

                    <div className="max-w-xs mx-auto">
                        <StaticImage src="../images/payment.png" title="Instant Cash Offers" alt="Instant Cash Offers" className="w-14" />
                        <p className="pt-2 font-bold">Close And Receive Your Cash</p>
                        <p>Get your money in as little as a few days, you decide the pace</p>
                    </div>

                    <div className="flex justify-center items-center pt-8">
                        <ArrowLeft />
                    </div>

                    <div className="max-w-xs mx-auto">
                        <StaticImage src="../images/email.png" title="No Commissions" alt="No Commissions" className="w-14" />
                        <p className="pt-2 font-bold">Receive An Offer Same Day</p>
                        <p>We take care of all the paperwork and hassle that comes after accepting the offer</p>
                    </div>
                </div>
            </div>
        )
    }
}