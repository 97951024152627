import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { HowItWorksBody } from "../components/how-it-works-body"
import { PriceCalcBanner } from "../components/price-calc-banner"
import { ThreeCards } from "../components/three-card"

const HowItWorksPage = (props) => {
    return (
        <Layout>
            <Seo
                title="How The Process Works - Sell Your Home Fast In Sacramento"
                description="We are a local Sacramento house buying company, and we can make you a fast & fair offer if you are 
                trying to sell your Sacramento House. If you want to sell your house fast in Sacramento, let us give you a 
                FREE no-obligation offer so that you can sell your Sacramento House today!"
                keywords={[
                    "sell my house in sacramento",
                    "selling my house in sacramento",
                ]}
                location={props.location.pathname}
            />
            <div>
                <HowItWorksBody />

                <div className="bg-dhredopaque">
                    <PriceCalcBanner />
                </div>

                <ThreeCards />

            </div>
        </Layout>
    )
}
export default HowItWorksPage